/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'tvbQvE9QFB5SxYFoauYynL.png': { uri: '/images/tvbQvE9QFB5SxYFoauYynL.png' },
'wjKFBdxgTDeqsuwZhfrtNy.png': { uri: '/images/wjKFBdxgTDeqsuwZhfrtNy.png' },
'4Y1zyg63HEsBP8zwK7JrpX.png': { uri: '/images/4Y1zyg63HEsBP8zwK7JrpX.png' },
'81Sbz6a5nRyu6JQZZP6WBc.png': { uri: '/images/81Sbz6a5nRyu6JQZZP6WBc.png' },
'69LgAPaMmJw2zspGycDkSL.png': { uri: '/images/69LgAPaMmJw2zspGycDkSL.png' },
'krCyBQUm43vPf5zE8eeq55.png': { uri: '/images/krCyBQUm43vPf5zE8eeq55.png' },
'tiEdQ2ybV5hAfWqB5jLQ17.png': { uri: '/images/tiEdQ2ybV5hAfWqB5jLQ17.png' },
'cEh7ZTpbzNZk1LdMVBEdNb.png': { uri: '/images/cEh7ZTpbzNZk1LdMVBEdNb.png' },
't6WBkYA49tPEWJezrE6QsV.png': { uri: '/images/t6WBkYA49tPEWJezrE6QsV.png' },
'mvtJ1GTPo3mMWbskFFb4ZD.png': { uri: '/images/mvtJ1GTPo3mMWbskFFb4ZD.png' },
'ihB7dxGSnQ3xTdjybwkJDv.png': { uri: '/images/ihB7dxGSnQ3xTdjybwkJDv.png' },
's9g6LuhSePz3zbBPRuoZrf.png': { uri: '/images/s9g6LuhSePz3zbBPRuoZrf.png' },
'gB3ACQxhuYVmAxd4DrG9nj.png': { uri: '/images/gB3ACQxhuYVmAxd4DrG9nj.png' },
'e5fYfvhUg9k96gQq24EFzm.png': { uri: '/images/e5fYfvhUg9k96gQq24EFzm.png' },
'a4RRh2RcMjzXwEPsj3c8jK.png': { uri: '/images/a4RRh2RcMjzXwEPsj3c8jK.png' },
'3D2tWTqL94seGi5qixKvT6.png': { uri: '/images/3D2tWTqL94seGi5qixKvT6.png' },
'p1zpWzG2XC9dbPyGthGUCZ.png': { uri: '/images/p1zpWzG2XC9dbPyGthGUCZ.png' },
'q4EFbj1Eh9fZNwMGWrdVae.png': { uri: '/images/q4EFbj1Eh9fZNwMGWrdVae.png' },
'8yyuHcD7ueb8Ng8ohZ3MK1.png': { uri: '/images/8yyuHcD7ueb8Ng8ohZ3MK1.png' },
'bUbDFEdTHqzCnccSmJzxfT.png': { uri: '/images/bUbDFEdTHqzCnccSmJzxfT.png' },
'saB3HaDtv32KSPSgFQHQiS.png': { uri: '/images/saB3HaDtv32KSPSgFQHQiS.png' },
'mRL1b1bsmJbW1WkMFZUfVQ.png': { uri: '/images/mRL1b1bsmJbW1WkMFZUfVQ.png' },
'4Vfn7jVVoeVCefeBZ77ZPE.png': { uri: '/images/4Vfn7jVVoeVCefeBZ77ZPE.png' },
'5DMtpy2hPVFoZUv51fCvq8.png': { uri: '/images/5DMtpy2hPVFoZUv51fCvq8.png' },
'tnwFj6ZSLoDs7v9UPRSNbL.png': { uri: '/images/tnwFj6ZSLoDs7v9UPRSNbL.png' },
'hTpCbcE4zXadcV8LLNM2hz.png': { uri: '/images/hTpCbcE4zXadcV8LLNM2hz.png' },
'kdkjZZgHfUbLtpPrYakcih.png': { uri: '/images/kdkjZZgHfUbLtpPrYakcih.png' },
'2qqYZAjLzNoV9msqTuPApG.png': { uri: '/images/2qqYZAjLzNoV9msqTuPApG.png' },
'bNjy4461rD776xux1ZcikM.png': { uri: '/images/bNjy4461rD776xux1ZcikM.png' },
'5YuFECz9D2n6azhHJcMZce.png': { uri: '/images/5YuFECz9D2n6azhHJcMZce.png' },
'e1RmZTr3xyLJHWRz1Wyjcu.png': { uri: '/images/e1RmZTr3xyLJHWRz1Wyjcu.png' },
'2eYjiVhP8FUH4NMwMGvtLX.png': { uri: '/images/2eYjiVhP8FUH4NMwMGvtLX.png' },
'gyw47eksp8YiiShRrnutDy.png': { uri: '/images/gyw47eksp8YiiShRrnutDy.png' },
'5EAgKQYvuksMFZhMomicTH.png': { uri: '/images/5EAgKQYvuksMFZhMomicTH.png' },
'f9G5NLbRBfTZT6cGKDjEqt.png': { uri: '/images/f9G5NLbRBfTZT6cGKDjEqt.png' },
'74nKsdv1mkk175MpbG43rU.png': { uri: '/images/74nKsdv1mkk175MpbG43rU.png' },
'rRnrLvtyCGVRquaz9Eq19B.png': { uri: '/images/rRnrLvtyCGVRquaz9Eq19B.png' },
'1hTCcBQoYiZicGDhW7diSy.png': { uri: '/images/1hTCcBQoYiZicGDhW7diSy.png' },
'ak1MJ4VJ8u8pGEN9sHJt5z.png': { uri: '/images/ak1MJ4VJ8u8pGEN9sHJt5z.png' },
'8FSs3T8nrwF8LivQZn8Zyt.png': { uri: '/images/8FSs3T8nrwF8LivQZn8Zyt.png' },
'm9G22QKQqKTswx8Rpwo2C7.png': { uri: '/images/m9G22QKQqKTswx8Rpwo2C7.png' },
'gTjMUvWNHgtkJkp27t11gE.png': { uri: '/images/gTjMUvWNHgtkJkp27t11gE.png' },
'wkkzNAxLKV7jJm8g48NekZ.png': { uri: '/images/wkkzNAxLKV7jJm8g48NekZ.png' },
'ru5tdkDb3ZBxDbvsj7Hhn3.png': { uri: '/images/ru5tdkDb3ZBxDbvsj7Hhn3.png' },
'jEgSboCQ8cSXYs3TTTudjK.png': { uri: '/images/jEgSboCQ8cSXYs3TTTudjK.png' },
'5E6S6zQwEZDM3JyS2mu4h6.png': { uri: '/images/5E6S6zQwEZDM3JyS2mu4h6.png' },
'a3NurWq9qCWDmMwAaok2wG.png': { uri: '/images/a3NurWq9qCWDmMwAaok2wG.png' },
'b6N3GJjaCgkAq5gGvMRFV7.png': { uri: '/images/b6N3GJjaCgkAq5gGvMRFV7.png' },
'cZX6r5FYqjiMcNYi5XmT2L.png': { uri: '/images/cZX6r5FYqjiMcNYi5XmT2L.png' },
'e62xSrDRtzjrNAQa5rXvJu.jpg': { uri: '/images/e62xSrDRtzjrNAQa5rXvJu.jpg' },
'4AV5YLwiBWfdffcPBSGSY5.jpg': { uri: '/images/4AV5YLwiBWfdffcPBSGSY5.jpg' },
'tuGAfCwchVUbgJusszr1Ug.png': { uri: '/images/tuGAfCwchVUbgJusszr1Ug.png' },
'4F2iCiMvgVK3RXwfytypUB.png': { uri: '/images/4F2iCiMvgVK3RXwfytypUB.png' }
}

export default imageStaticSourcesByFileName
